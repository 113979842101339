<template>
  <div id="personal">
    <p>超级码农，天天向上</p>
  </div>
</template>

<script>
  export default {
    name: "personal",
    data() {
      return {};
    },
    components: {
    },
    methods: {},
    destroyed() {
      console.log('gerenxiaohui')
    }
  };
</script>
<style lang='less'>
  #personal {
    height: 100vh;
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
  }

  #personal p{
    font-size: 5em;
    font-weight: bold;
  }
</style>